import React, {useEffect} from 'react';
import styled from "styled-components";

const ChatBot = () => {

    const toggleChatWindow = () => {
        const chatWindow = document.getElementById('chatWindow');
        chatWindow.style.display = chatWindow.style.display === 'flex' ? 'none' : 'flex';
    };

    const startConversation = () => {
        document.getElementById('chatHome').style.display = 'none'; // Hide the initial content
        document.getElementById('chatIframe').style.display = 'block'; // Show the iframe
    };

    const goBackToHome = () => {
        document.getElementById('chatIframe').style.display = 'none'; // Hide the iframe
        document.getElementById('chatHome').style.display = 'block'; // Show the initial content
    };



    return (<StyledComponent className={''}>
        <div className="chat-icon" onClick={toggleChatWindow}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 5000 5000">
                <g>
                    <path className="st0" d="M924.4,175.9h2237.4c339.5,0,617.2,277.8,617.2,617.2v1581.6c0,339.5-277.7,617.2-617.2,617.2H1698.3
		l-923.4,923.5L882,2990.5c-319.9-22-574.9-290.5-574.9-615.8V793.1C307.1,453.7,584.9,175.9,924.4,175.9L924.4,175.9z M1400.4,3717
		c112.1,113.2,267.2,183.6,438.1,183.6H3302l923.4,923.4L4118.3,3899c320-22,574.9-290.4,574.9-615.7V1701.7
		c0-337.8-275-614.4-612.2-617.2v1290.2c0,506.2-413,919.2-919.2,919.2H1823.4L1400.4,3717L1400.4,3717z M1078.7,819.7
		c-90.2,0-163.9,73.8-163.9,163.9v0.1c0,90.2,73.8,163.9,163.9,163.9h1928.8c90.2,0,164-73.7,164-163.9v-0.1
		c0-90.1-73.8-163.9-164-163.9H1078.7L1078.7,819.7z M1078.7,1433.2c-90.2,0-163.9,73.8-163.9,164l0,0
		c0,90.1,73.8,163.9,163.9,163.9h1422.5c90.1,0,163.9-73.8,163.9-163.9l0,0c0-90.2-73.8-164-163.9-164H1078.7z"/>
                </g>
            </svg>
        </div>

        <div className="chat-window" id="chatWindow">
            <div className="chat-home" id="chatHome">
                <div className="chat-img">
                    <img src="/images/static/logo.svg" alt="" width="30%" />
                </div>
                <div className="chat-content">
                    <h1>Hi there! 👋</h1>
                    <p>How can we help you today?</p>
                    <button onClick={startConversation}><img src="/images/static/logo.svg" alt="" width="40%"/><p>Start A Conversation</p></button>
                </div>
            </div>

            <div id="chatIframe" style={{display: 'none'}}>

                <div className="chat-header">
                    <div className="header-text">
                        <div className="back-button" onClick={goBackToHome}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
                            </svg>
                            <img src="/images/static/logo.svg" alt="" width="40%"/>
                        </div>
                        <h6>We're Here To Help.<br/>Let's Talk!</h6>
                    </div>
                </div>

                <div className="chat-iframe">
                    <iframe className="iframe" src="https://cdn.botpress.cloud/webchat/v2.2/shareable.html?configUrl=https://files.bpcontent.cloud/2025/01/22/12/20250122120539-N808KQK0.json">
                    </iframe>
                </div>

            </div>
        </div>

    </StyledComponent>);
};

const StyledComponent = styled.section`

    position: relative;
    z-index: 99999;

    .chat-icon {
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 60px;
        height: 60px;
        background: #a79832;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        z-index: 1000;

        svg {
            width: 35px;
            height: 35px;
        }

        .st0 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #FFFFFF;
        }
    }

    .chat-window {
        position: fixed;
        bottom: 90px;
        right: 20px;
        width: 350px;
        height: 450px;
        background: #ffffff;
        border-radius: 15px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        overflow: hidden;
        display: none;
        flex-direction: column;
        z-index: 999;

        .chat-home {
            background: linear-gradient(90deg, #978c21, #978c21);
            height: 100%;
            width: 100%;
            padding: 20px 20px;
        }
    }

    #chatIframe {
        height: calc(100% - -2px);
        position: relative;
        width: 100%;

        .chat-header {
            background: linear-gradient(90deg, #978c21, #978c21);
            color: white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            position: absolute;
            width: 100%;

            h6 {
                color: white;
                font-size: 16px;
                line-height: 20px;
            }

            img {
                width: 40px;
                height: 40px;
                background: #3C3C3B;
                margin-right: 10px;
                border-radius: 50px;
                padding: 5px;
            }

            .header-text {
                font-size: 16px;
                font-weight: bold;
                display: flex;
                color: #fff;
                align-items: center;

                .back-button {
                    cursor: pointer;
                    svg{
                        height: 25px;
                        margin-right: 10px;
                        path{
                            fill: white;
                        }
                    }
                }

                i {
                    margin-right: 10px;
                    font-size: 19px;
                    font-weight: 500;
                }
            }
        }

        .chat-iframe {
            width: 100%;
            border: none;
            height: 100%;
            padding: 10px;    
            background: #101010;
            .iframe {
                width: 100%;
                border: none;
                height: 100%;
            }
        }
    }

    .chat-content {
        flex-grow: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #333333;
        height: calc(100% - 80px);
        background: linear-gradient(93deg, rgba(255, 255, 255, .1) 3.13%, rgba(255, 255, 255, .1) 98.7%);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        border-radius: 24px;
        border: 2px solid #ffffff33;
        margin-top: 20px;
    }

    .chat-content {
        button {
            padding: 10px 20px;
            font-size: 16px;
            background: #3C3C3B;
            color: #fff;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: center;
            align-items: center;

            img {
                margin-bottom: 10px;
            }

            p {
                margin: 0;
            }
        }

        h1 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 10px;
            color: #fff;
            line-height: 30px;
        }

        p {
            font-size: 16px;
            margin-bottom: 20px;
            color: #fff;
        }
    }

`;

export default ChatBot;
